#loader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 10;
  background-image: none;
  transition: transform 1s ease-in-out 0.5s;
}

#container-loader {
  position: absolute;
  width: 100vw;
  height: 200vh;
  height: calc(var(--vh, 1vh) * 200);
  z-index: 10;
  background: linear-gradient(360deg, #e1e5e1 0%, #95aa9c 100%);
  transition: transform 2s ease-in-out 2s, opacity 2s ease-in-out 0.5s;
}

#container-loader.hidden {
  transform: translateY(-300vh);
  transform: translateY(calc(-var(--vh, 1vh) * 300));
  opacity: 0;
}

#loader > .building {
  width: 80vw;
  height: auto;
  flex-shrink: 0;
  position: absolute;
  transition: transform 0.5s ease;
  max-width: min(80vw, calc(3.5 * 30vh));
  max-width: min(80vw, calc(calc(var(--vh, 1vh) * 105)));
}

#loader > .building {
  transition: all 0.5s ease;
}
#loader > .building.bottom {
  /* bottom: min(80px, 4vh); */
  bottom: -500px;
}

#loader > .building.left {
  left: -500px;
}

#loader > .building.right {
  right: -500px;
}

#loader > .building.top {
  top: -500px;
}

#loader > .building.bottom.loaded {
  /* bottom: min(80px, 4vh); */
  bottom: calc(100vh - var(--vh, 1vh) * 96);
}

#loader > .building.left.loaded {
  left: min(24px, 2vw);
}

#loader > .building.right.loaded {
  right: min(24px, 2vw);
}

#loader > .building.top.loaded {
  top: min(80px, 4vh);
}

#loader.hidden > .building.top {
  transform: translateX(-100vw);
}

#loader.hidden > .building.bottom {
  transform: translateX(100vw);
}

.middle {
  height: 100%;
  top: 0px;
  flex-direction: column;
  gap: 30px;
}

.loaderBar {
  display: flex;
  height: 44px;
  justify-content: left;
  align-items: left;
  gap: 8px;
  align-self: stretch;
  border-radius: 48px;
  background: var(--color-grey-400, #999);
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.loaderBar > .cta {
  color: #cccccc;
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Baseline */
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
  margin: auto;
  z-index: 2;
  pointer-events: none;
}

.loaderBar.active > .cta {
  color: var(--White, #fff);
}

.logoContainer {
  color: var(--Blackish, #272727);
  leading-trim: both;
  text-edge: cap;

  /* Portal Desktop/Heading 3 */
  font-family: Literata;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
}

#espa-logo {
  position: absolute;
  bottom: -100px;
  right: 16px;
  max-width: 90vw;
  border: 20px solid white;
  border-radius: 16px;
  width: 400px;
  transition: bottom 0.6s ease-in-out;
}

@media (hover: hover) {
  .loaderBar.active > .progressBar:hover {
    background: #375330;
    cursor: pointer;
  }
}
.progressBar {
  height: 100%;
  background: #557767;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
  transition: width 0.3s ease-in-out;
}

.loaderContent {
  display: flex;
  width: 291px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: calc(100vw - 16px);
  transition: opacity 0.2s ease-in;
}

.loaderContent > img {
  max-width: 100%;
}

#loader.hidden .loaderContent {
  opacity: 0;
}

.loaderControl {
  display: flex;
  width: 100%;
  gap: 8px;
}

.loaderControl > div {
  flex-grow: 1;
}

.loaderControl .button {
  width: 100% !important;
}
