.badge {
  display: inline-flex;
  height: 64px;
  padding: 24px;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 200px;
  background: #faf4e9cc;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  box-sizing: border-box;
  max-width: 90vw;
}

.badge-container {
  width: 100vw;
  display: flex;
  justify-content: space-around;
  order: 3;
}

.badge-icon {
  width: 40px;
  height: 40px;
}

.badge-text {
  color: #736446;
  leading-trim: both;
  text-edge: cap;
  font-family: Helvetica Neue;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.badge.hide {
  display: none;
}

@media (max-width: 992px) {
  .badge-container {
    justify-content: space-between;
    order: 3;
  }
  .badge {
    display: inline-flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 200px;
    height: fit-content;
    padding-right: 20px;
  }

  .badge-icon {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 1px 5px;
    justify-content: center;
    align-items: center;
  }

  .badge-text {
    leading-trim: both;
    text-edge: cap;
    /* Portal Desktop/Baseline Medium */
    font-family: Helvetica Neue;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .center-container.badge-container {
    justify-content: left;
    margin: 8px;
    z-index: 0;
  }
}
